import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';

export default function Group() {
    const notify = useNotify()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const auth = useAuth()
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - Radius Grupos"
      }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Grupo'
                endpoint='/groupreply'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton
                                    disabled={!auth.hasPerm("group.create")}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'groupname',
                        label: 'Grupo',
                    },
                    {
                        name: 'attribute',
                        label: 'Atributo',
                    },
                    {
                        name: 'value',
                        label: 'Valor',
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                disabled={!auth.hasPerm("group.update")}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar'}) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = useState(true)
    useEffect(() => {
        setState(modelState)
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/groupreply', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id])
    const modelState = {
        groupname: '',
        attribute: '',
        value: '',
    }
    const [state, setState] = useState(modelState)
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/groupreply', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Grupo atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/groupreply/create', state)
            .then(res => {
                console.log(res)
                if (res.data.errors) {
                    error.setErrors(res.data.errors)
                    return
                }
                handlerClose()
                notify.setNotify({ open: true, message: 'Grupo criado com sucesso.', severity: 'success' })
                error.setErrors([])
            })
            .catch(e => 
                notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Grupo</Typography>
                        <IconButton 
                        disabled={!auth.hasPerm("group.delete")}
                        sx={{ display: id ? 'block' : 'none' }}
                        onClick={()=>{
                             if (confirm('Deseja excluir o grupo?')){
                                    delete_one('/groupreply', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Grupo deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                             }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('groupname')}
                        helperText={error.msgError('groupname')}
                        label='Grupo'
                        value={state?.groupname}
                        name={'groupname'}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify("attribute")}
                        helperText={error.msgError("attribute")}
                        label='Atributo'
                        name={"attribute"}
                        value={state?.attribute}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify('value')}
                        helperText={error.msgError('value')}
                        label='Valor'
                        name={'value'}
                        value={state?.value}
                        onChange={handleChange}
                    />
                   
                </Box>

            </FormDialog>
        </Box>
    )
}


