import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import KeyIcon from '@mui/icons-material/Key';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import Backdrop from '@mui/material/Backdrop';
import SyncIcon from '@mui/icons-material/Sync';
import { useLoading } from '../../components/LoadingProvider';

export default function Node() {
    const notify = useNotify()
    const auth = useAuth()
    const loading = useLoading()
    const { isLoading, setLoading } = loading

    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - DNS Servidores DNS"
    }, [])
    return (
        <>
        
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Servidores DNS'
                endpoint='/dns/node'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem("datatable" + "'/dns/node"); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm("dns_node.create")}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'name',
                        label: 'Descrição',
                    },
                    {
                        name: 'ip',
                        label: 'IP',
                    },
                    {
                        name: 'port',
                        label: 'Porta',
                    },
                    {
                        name: 'is_sync',
                        label: 'Sincronizado',
                        options: {
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return value ? <CheckIcon sx={{ color: green[500] }} /> : <CloseIcon sx={{ color: red[500] }} />
                            }
                        }
                    },
                    {
                        name: "is_credentials_valid",
                        label: "Login",
                        options: {
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return value ? <CheckIcon sx={{ color: green[500] }} /> : <CloseIcon sx={{ color: red[500] }} />
                            }

                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                disabled={!auth.hasPerm("dns_node.update")}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>
                                       
                                            <Tooltip title="Validar Login">
                                                <IconButton
                                                    disabled={!auth.hasPerm("dns_node.update")}
                                                    onClick={() => {
                                                        const id = tableMeta.rowData[0]
                                                        setLoading(true)
                                                        api.get(`/dns/node/${id}/valid_credentials`).then(res => {
                                                            console.log(res)
                                                            if (res.data?.error) {
                                                                notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                                                                setRefresh(true)
                                                                return

                                                            }
                                                            notify.setNotify({ open: true, message: 'Login validado com sucesso.', severity: 'success' })
                                                            setRefresh(true)
                                                        }).finally(() =>{ 
                                                            setLoading(false)
                                                        }
                                                        )
                                                    }}
                                                >
                                                    <KeyIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sincronizar">
                                                <IconButton
                                                disabled={!auth.hasPerm("dns_node.update")}
                                                onClick={() => {
                                                    const id = tableMeta.rowData[0]
                                                    if (!confirm('Deseja sincronizar o servidor DNS? \nA SINCRONIZAÇÃO IRÁ APAGAR OS REGISTROS EXISTENTES E IRÁ ADICIONAR O QUE ESTÃO NO SISTEMA.')) return
                                                    setLoading(true)
                                                    api.get(`/dns/node/${id}/sync`).then(res => {
                                                        console.log(res)
                                                        if (res.data?.error) {
                                                            notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                                                            setRefresh(true)
                                                            return

                                                        }
                                                        notify.setNotify({ open: true, message: 'Servidor DNS sincronizado com sucesso.', severity: 'success' })
                                                        setRefresh(true)
                                                    }).finally(() =>{ 
                                                        setLoading(false)
                                                    }
                                                    )
                                                }}
                                                >
                                                <SyncIcon/>
                                                </IconButton>
                                            </Tooltip>
                                       
                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = useState(true)
    useEffect(() => {
        setState(modelState)
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/dns/node', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id])
    const modelState = {
        ip: '',
        name: '',
        username: '',
        password: '',
        port: '',
    }
    const [state, setState] = useState(modelState)
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/dns/node', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Servidor DNS atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/dns/node/create', state)
                .then(res => {
                    console.log(res)
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Servidor DNS criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Servidor DNS</Typography>
                        <IconButton
                            disabled={!auth.hasPerm("dns_node.delete")}
                            sx={{ display: id ? 'block' : 'none' }}
                            onClick={() => {
                                if (confirm('Deseja excluir o servidor DNS?')) {
                                    delete_one('/dns/node', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Servidor DNS deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        sx={{ mb: 2, mt: 1 }}
                        error={error.verify("name")}
                        helperText={error.msgError("name")}
                        label='Descrição'
                        name={"name"}
                        value={state.name}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify('ip')}
                        helperText={error.msgError('ip')}
                        label='IP'
                        value={state.ip}
                        name={'ip'}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        name={'username'}
                        value={state.username}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        label='Senha'
                        name={'password'}
                        value={state.password}
                        onChange={handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        error={error.verify('port')}
                        helperText={error.msgError('port')}
                        label='Porta'
                        name={'port'}
                        value={state.port}
                        onChange={handleChange}
                    />


                </Box>

            </FormDialog>
        </Box>
    )
}


