import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';

import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import moment from "moment";
import 'moment/locale/pt-br'

export default function Group() {
    const notify = useNotify()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - Gate Sessões"
      }, [])
    return (
        <>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Logs de Sessões'
                endpoint='/radacct'
                options={{}}
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'username',
                        label: 'Usuário',
                        options:{
                            //setCellProps: () => ({ style: {  ml:3 }}),

                        }
                    },
                   
                    {
                        name: 'nasipaddress',
                        label: 'Equipamento',
                    },
                    {
                        name: "framedipaddress",
                        label: "Origem do Acesso",
                        options:{
                            customBodyRender: (value, tableMeta, updateValue) => {
                                //frameipaddress or callingstationid
                                const callingstationid = tableMeta.rowData[4]
                                return <span>{value ? value : callingstationid}</span>
                                
                            },

                        }
                    },
                    {
                        name: "callingstationid",
                        label: "Calling Station ID",
                        options:{
                            display: false
                        }
                    },
                    {
                        name: 'acctstarttime',
                        label: 'Data de Autenticação',
                        options:{
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,

                        }
                    },
                    {
                        name: 'acctstoptime',
                        label: 'Data de Desconexão',
                        options:{
                            customBodyRender: value => <span>{value ? moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss') : 'Ativo'}</span>,

                        }
                    },
                    { 
                        name: "acctsessiontime",
                        label: "Tempo de Sessão",
                        options:{
                            customBodyRender: value => <span>{value ? moment.utc(value*1000).format('HH:mm:ss') : 'Ativo'}</span>,

                        }
                    }
                    
                ]}

            />
        </>
    )
}






