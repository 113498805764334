import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';

import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

import moment from "moment";
import 'moment/locale/pt-br'

export default function Group() {
    const notify = useNotify()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - Gate Logs Fail"
      }, [])
    return (
        <>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Logs com falha de autenticação'
                endpoint='/radpostauth'
                options={{}}
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'username',
                        label: 'Usuário',
                                            },
                   
                    {
                        name: 'password',
                        label: 'Senha',
                                            },
                    {
                        name: 'authdate',
                        label: 'Data de Autenticação',
                        options:{
                             
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,

                        }
                    },
                   
                ]}

            />
        </>
    )
}






