import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import api from '../../services/api';

import { useLoading } from '../../components/LoadingProvider';

export default function Node() {
    const notify = useNotify()
    const auth = useAuth()
    const loading =  useLoading()
    const { isLoading, setLoading } = loading
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - DNS ACL"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Classes Permitidas'
                endpoint='/dns/acl'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem("datatable" + "'/dns/acl"); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm("dns_acl.create")}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'ip_range',
                        label: 'Domínio',
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const id = tableMeta.rowData[0]
                                return (
                                    <>
                                        <IconButton
                            disabled={!auth.hasPerm("dns_acl.delete")}
                            onClick={() => {
                                if (confirm('Deseja excluir a classe?')) {
                                    setLoading(true)
                                    api.delete(`dns/acl/${id}`).then(res=>{
                                        if (res.data.nodes){
                                            const type_error = res.data.nodes.every(node => node.result == false) ? 'error' : res.data.nodes.every(node => node.result == true) ? 'success' : 'warning'
                                            if (res.data.nodes?.length == 0){
                                                notify.setNotify({ open: true, message: 'As informações foram salvas no sistema, porém nenhum DNS foi atualizado', severity: "warning" })
                                                return
                                            }
                                            const msg = res.data.nodes.map(node => `${node.name}: ${node.result ==1 ? "Executado com sucesso." : "Falhou."}` ).join('\n')
                                            notify.setNotify({ open: true, message: msg, severity: type_error })
                                            return
                                        }
                                        if (res.data.error){
                                            notify.setNotify({ open: true, message: res.error, severity: 'error' })
                                            return
                                        } 
                                        notify.setNotify({ open: true, message: 'ACL deletada com sucesso.', severity: 'success' })
                                    }).catch(e=>{
                                        notify.setNotify({ open: true, message: e.message, severity: 'error' })
                                    
                                    }).finally(()=>{
                                        setLoading(false)
                                        setRefresh(true)
                                    })
                                    
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const loading =  useLoading()
    const { isLoading, setLoading } = loading


    const [state, setState] = useState({
        ip_range: '',

    })
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }
    
    useEffect(() => {
        if (open){

            setState({
             ip_range: '',
            })
            error.setErrors([])
        }
    },[open])
    const wrapperSubmit = () => {
        setLoading(true)
        create('/dns/acl/create', state)
                .then(res => {
                    if (res.data.nodes){
                        const type_error = res.data.nodes.every(node => node.result == false) ? 'error' : res.data.nodes.every(node => node.result == true) ? 'success' : 'warning'
                        if (res.data.nodes?.length == 0){
                            notify.setNotify({ open: true, message: 'As informações foram salvas no sistema, porém nenhum DNS foi atualizado', severity: "warning" })
                            error.setErrors([])
                            handlerClose()
                            return
                        }
                        const msg = res.data.nodes.map(node => `${node.name}: ${node.result ==1 ? "Executado com sucesso." : "Falhou."}` ).join('\n')
                        notify.setNotify({ open: true, message: msg, severity: type_error })
                        handlerClose()
                    } else if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    } else  if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    } else {
                        notify.setNotify({ open: true, message: 'ACL criada com sucesso.', severity: 'success' })
                        error.setErrors([])
                    }
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
                .finally(()=>{
                    setLoading(false)
                    handlerSubmit()
                })
        

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
               
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Classe</Typography>
                        
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        sx={{ mb: 2, mt: 2}}
                        error={error.verify("ip_range")}
                        helperText={error.msgError("ip_range")}
                        label='Network'
                        name={"ip_range"}
                        value={state.ip_range}
                        onChange={handleChange}
                    />
                   
                   


                </Box>

            </FormDialog>
        </Box>
    )
}


