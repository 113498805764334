import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import api from "../services/api";
import { useFormError } from "./Datatable/FormErrorProvider";

export default function GroupSelect({ value, handler, label  }) {
    const [groups, setGroups] = React.useState([]);
    const error = useFormError()
    useEffect(() => {
        api.get('/access/groups/all').then(res=>{
            setGroups(res.data)
            
        })
    },[])
    return (
       <FormControl fullWidth>
         <InputLabel id="select-label-group">{label}</InputLabel>
              <Select
                label={label}
                labelId="select-label-group"
                error={error.verify("groupname")}
                helperText={error.msgError("groupname")}
                value={value}
                onChange={handler}
                inputProps={{
                 name: 'groupname',
                }}
                >
                {groups?.map((group, index) => <MenuItem key={index} value={group.groupname}>{group.groupname}</MenuItem>)}
                </Select>
        </FormControl>
    );
    }