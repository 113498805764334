import { Autocomplete, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ThemeProvider, createTheme } from "@mui/material"


export default function FormDialog({ open, title, handlerClose = () => { }, handlerSubmit = () => { }, children = <></>, btnSubmit = "Salvar", btnClose = "Fechar" }, isLoaded = true) {

    return (
        <ThemeProvider theme={createTheme({
            typography:{
                fontFamily:[
                  'sans-serif',
                  'Roboto',
                  'Montserrat',
                ]
              },
              palette: {
                mode: 'light',
                primary: {
                  main: "#e01b29",
                },
                }
        })}>

            <Dialog open={open} onClose={handlerClose} aria-labelledby="form-dialog-title" fullWidth
            
            sx={{
               overflowY: 'scroll'
            }}
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={!isLoaded}
                            
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                   
                        {children}
                        


                   

                </DialogContent>
                <DialogActions>
                    <Button onClick={handlerClose} variant="outlined">
                        {btnClose}
                    </Button>
                    <Button onClick={handlerSubmit} variant="contained"  color="primary">
                        {btnSubmit}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>

    )
}


export function Text({ label, name, value, onChange, type = "text", props = {}, multiline = false, rows = 1, required = false }) {
    return (
        <TextField
            required={required}
            multiline={multiline}
            rows={rows}
            autoFocus
            name={name}
            margin="dense"
            id={label}
            label={label}
            type={type}
            fullWidth
            value={value}
            onChange={onChange}
            {...props}
        />
    )
}

export function Select({ label, value, onChange, size, options = [], required = false, props = {}, multiple = false, placeholder = "Selecione uma opção" }) {
    return (
        <Autocomplete
            multiple={multiple}
            id="tags-standard"
            size={size}
            options={options}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder="Favorites"
                />
            )}
        />
    )
}
