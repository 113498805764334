import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import DashboardLayout from './layout/dashboard/Dashboard';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BlankPage from './components/BlankPage';
import First from './pages/first';
import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import NotifyProvider from './components/Datatable/NotifyProvider';
import FormErrorProvider from './components/Datatable/FormErrorProvider';
import { AuthProvider, RequireAuth } from './components/AuthProvider';
import LoginPage from './pages/login/LoginPage';
import Access from './pages/driade/acessos';
import Group from './pages/driade/grupos';
import NAS from './pages/driade/nas';
import LogAuth from './pages/driade/radpostauth';
import Session from './pages/driade/radacct';
import Recover from './pages/recovery';
import ChangePassword from './pages/recovery/changePassword';
import Role from './pages/user/role';
import User from './pages/user/user';
import globalRouter from './components/globalRouter';
import Node from './pages/oceanide/node';
import LocalZone from './pages/oceanide/localzone';
import ACL from './pages/oceanide/acl';
import Blacklist from './pages/oceanide/blacklist';
import { LoadingProvider } from './components/LoadingProvider';
import PegeiaDatasource from './pages/pegeia/datasource';
import PegeiaSearch from './pages/pegeia/search';
import Dashboard from './pages/dashboard';
import IPPool from './pages/driade/ip_pool';

export default function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  return (
    <AuthProvider>
    <NotifyProvider>
      <LoadingProvider>
    <FormErrorProvider>

    <Routes>
      <Route path="/" element={
      <RequireAuth>
        <DashboardLayout />
      </RequireAuth>   
      }>
        <Route path="/" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="blank" element={<BlankPage />} />
        <Route path="groupip" element={<IPPool />} />
        <Route path="access" element={<Access />} />
        <Route path="group" element={<Group />} />
        <Route path="nas" element={<NAS />} />
        <Route path="log_auth_fail" element={<LogAuth />} />
        <Route path="log_session" element={<Session />} />
        <Route path="role" element={<Role />} />
        <Route path="user" element={<User />} />
        <Route path="dns_node" element={<Node />} />
        <Route path="dns_localzone" element={<LocalZone />} />
        <Route path="dns_acl" element={<ACL />} />
        <Route path="dns_blacklist" element={<Blacklist />} />
        <Route path="403" element={<NotAuthorized />} />
        <Route path="*" element={<NotFound />} />
        <Route path="pegeia_source" element={<PegeiaDatasource />} />
        <Route path="pegeia_search" element={<PegeiaSearch />} />
      </Route>
      <Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recovery" element={<Recover />} />
        <Route path="/change-password" element={<ChangePassword />} />
        
        <Route path="*" element={<NotFound />} />

      </Route>
    </Routes>
    </FormErrorProvider>
    </LoadingProvider>
    </NotifyProvider>
    </AuthProvider>
  );
}



function NotFound() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
    <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: grey[900]}}>
        Error 404
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom sx={{ color: grey[900]}}>
        Página não encontrada.
      </Typography>
    </Box>
   
      </Container>
    </ThemeProvider>
  )
}

function NotAuthorized() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
    <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: grey[900]}}>
        Error 403
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom sx={{ color: grey[900]}}>
        Acesso negado.
      </Typography>
    </Box>
   
      </Container>
    </ThemeProvider>
  )
}