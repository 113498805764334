import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import api from "../services/api";
import { useFormError } from "./Datatable/FormErrorProvider";


export default function IpPoolSelect({ handler, value, disabled = false, ...props }) {
    const [groups, setGroups] = useState([])
    const error = useFormError()
   
    React.useLayoutEffect(() => {
        api.get('/access/groupsip/all').then(res=>{
            setGroups(res.data)
        })
    }, [])

    return (
        <FormControl
            {...props}
        >
            <Autocomplete
                multiple
                disabled={disabled}
                id="groups-outlined"
                options={groups}
                onChange={(event, newValue) => {
                    handler(newValue)
                }}
                key={(option) => option.description}
                noOptionsText={"Sem grupos"}

                isOptionEqualToValue={(option, value) => option.description === value.description}
                getOptionLabel={(option) => option.description}
                value={value}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        error={error.verify('groupip')}
                        helperText={error.msgError('groupip')}
                        {...params}
                        label="Pools de IP"
                    />
                )}
            />
        </FormControl>
    )
}
    