import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';

import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import Backdrop from '@mui/material/Backdrop';
import { useLoading } from '../../components/LoadingProvider';


export default function Node() {
    const notify = useNotify()
    const auth = useAuth()
    const loading = useLoading()
    const { isLoading, setLoading } = loading
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - DNS Domínios Bloqueados"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Domínios Bloqueados'
                endpoint='/dns/blacklist'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem("datatable" + "'/dns/blacklist"); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm("dns_blacklist.create")}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    selectableRows:"multiple",
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => 
                    <Tooltip title="Remover dominios da blacklist">
                
                    <IconButton 
                    disabled={!auth.hasPerm('dns_blacklist.update')}
                    onClick={() => {
                    const domains = selectedRows.data.map((row) => displayData[row.dataIndex].data[1]);
                
                      if (confirm('Deseja remover os domínios selecionados?')) {
                        setLoading(true)
                        api.post(`dns/blacklist/delete`,{blacklist: domains}).then(res=>{
                            if (res.data.nodes){
                                const type_error = res.data.nodes.every(node => node.result == false) ? 'error' : res.data.nodes.every(node => node.result == true) ? 'success' : 'warning'
                                const msg = res.data.nodes.map(node => `${node.name}: ${node.result ==1 ? "Executado com sucesso." : "Falhou."}` ).join('\n')
                                notify.setNotify({ open: true, message: msg, severity: type_error })
                                
                            }
                            else if (res.data.error){
                                notify.setNotify({ open: true, message: res.error, severity: 'error' })
                            } else {

                                notify.setNotify({ open: true, message: 'Dominio bloqueado deletado com sucesso.', severity: 'success' })
                            }
                        }).catch(e=>{
                            notify.setNotify({ open: true, message: e.message, severity: 'error' })
                        
                        }).finally(()=>{
                            setRefresh(true)
                            setLoading(false)
                            setSelectedRows([])
                        })
                      }
                      
                    }}><DeleteIcon /></IconButton>
                        </Tooltip>,
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'domain',
                        label: 'Domínio',
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                const domain = tableMeta.rowData[1]
                                return (
                                    <>
                                        <IconButton
                            disabled={!auth.hasPerm("dns_blacklist.delete")}
                            onClick={() => {
                                if (confirm('Deseja excluir o item?')) {
                                    setLoading(true)
                                    api.post(`dns/blacklist/delete`,{blacklist: [domain]}).then(res=>{
                                        if (res.data.nodes){
                                            const type_error = res.data.nodes.every(node => node.result == false) ? 'error' : res.data.nodes.every(node => node.result == true) ? 'success' : 'warning'
                                            if (res.data.nodes?.length == 0){
                                                notify.setNotify({ open: true, message: 'As informações foram salvas no sistema, porém nenhum DNS foi atualizado', severity: "warning" })
                                                return
                                            }
                                            const msg = res.data.nodes.map(node => `${node.name}: ${node.result ==1 ? "Executado com sucesso." : "Falhou."}` ).join('\n')
                                            notify.setNotify({ open: true, message: msg, severity: type_error })
                                           
                                        }
                                        else if (res.data.error){
                                            notify.setNotify({ open: true, message: res.error, severity: 'error' })
                                            return
                                        }else{
                                            notify.setNotify({ open: true, message: 'Dominio bloqueado deletado com sucesso.', severity: 'success' })
                                        }
                                    }).catch(e=>{
                                        notify.setNotify({ open: true, message: e.message, severity: 'error' })
                                    
                                    }).finally(()=>{
                                        setRefresh(true)
                                        setLoading(false)
                                    })
                                    
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()

    const loading = useLoading()
    const { isLoading, setLoading } = loading
    const onChangeDomains = (event) => {
        const inputValue = event.target.value;
        const regex = /^[a-zA-Z0-9,\-.\n ]+$/;
    if (regex.test(inputValue)) {
      setState({ ...state, blacklist: inputValue });
    }
    }


    const [state, setState] = useState({
        blacklist: '',

    })
    useEffect(() => {
        if (open){

            setState({
                blacklist: '',
            })
            error.setErrors([])
        }
    },[open])

    const wrapperSubmit = () => {
        setLoading(true)
        create('/dns/blacklist/create', {blacklist: state.blacklist.match(/[a-zA-Z0-9,\-.]+/g) })
                .then(res => {
                    if (res.data.nodes){
                        const type_error = res.data.nodes.every(node => node.result == false) ? 'error' : res.data.nodes.every(node => node.result == true) ? 'success' : 'warning'
                        if (res.data.nodes?.length == 0){
                            notify.setNotify({ open: true, message: 'As informações foram salvas no sistema, porém nenhum DNS foi atualizado', severity: "warning" })
                            error.setErrors([])
                            return
                        }
                        const msg = res.data.nodes.map(node => `${node.name}: ${node.result ==1 ? "Executado com sucesso." : "Falhou."}` ).join('\n')
                        notify.setNotify({ open: true, message: msg, severity: type_error })
                        
                    }
                    else if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }else{
                        notify.setNotify({ open: true, message: 'Dominio bloqueado com sucesso.', severity: 'success' })
                        error.setErrors([])
                    }
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
                .finally(()=>{
                        handlerSubmit()
                        setLoading(false)
                        handlerClose()
                })
        

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Domínios</Typography>
                        
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        multiline={true}
                        rows={10}
                        
                        sx={{ mb: 2, mt: 2}}
                        error={error.verify("blacklist")}
                        helperText={error.msgError("blacklist")}
                        label='Domínios'
                        name={"blacklist"}
                        value={state.blacklist}
                        onChange={onChangeDomains}
                    />
                 
                   


                </Box>

            </FormDialog>
        </Box>
    )
}


