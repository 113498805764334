import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, Chip, FormControl, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import { grey } from '@mui/material/colors';
import { Form } from 'react-router-dom';

export default function IPPool() {
    const notify = useNotify()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const auth = useAuth()
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - Radius Grupos"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Grupo'
                endpoint='/groupip'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('alarms'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton
                                    disabled={!auth.hasPerm("group.create")}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'description',
                        label: 'Nome',
                    },
                    {
                        name: 'ips',
                        label: 'IP Pools',
                        options: {
                            customBodyRender: (value) => value?.split(",").map((ip, index) => <Chip variant="outlined" sx={{ m: .5 }} key={index} label={ip} />)
                        },
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                disabled={!auth.hasPerm("group.update")}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = useState(true)
    const [ip, setIp] = useState('')
    useEffect(() => {
        setState(modelState)
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/groupip', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id])
    const modelState = {
        groupname: '',
        attribute: '',
        value: '',
    }
    const [state, setState] = useState(modelState)
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }
    function checkisIP(str) {
        const octet = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)';
        const mask = "([1-2]?[0-9]|3[0-2])";
        const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$|^${octet}\\.${octet}\\.${octet}\\.${octet}\\/${mask}$`);
        return regex.test(str);
    }
    const extractIPs = (ips_data) => ips_data?.match(/(\d{1,3}\.){3}\d{1,3}\/\d{1,2}|(\d{1,3}\.){3}\d{1,3}/g)
    const wrapperSubmit = () => {
        error.setErrors([])
        const ips = state.ips ?extractIPs(state.ips).join(",") : ""
        if (id) {
            update('/groupip', id, { ...state, ips }).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Grupo atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/groupip/create', { ...state, ips })
                .then(res => {
                    console.log(res)
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Grupo criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Grupo</Typography>
                        <IconButton
                            disabled={!auth.hasPerm("group.delete")}
                            sx={{ display: id ? 'block' : 'none' }}
                            onClick={() => {
                                if (confirm('Deseja excluir o grupo?')) {
                                    delete_one('/groupip', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Grupo deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <TextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Nome'
                        value={state?.description}
                        name={'description'}
                        onChange={handleChange}
                    />
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"} alignContent={"center"} gap={1}>
                        <FormControl fullWidth>

                            <TextField
                                sx={{
                                    mb: 2,
                                    borderColor: checkisIP(ip) ? grey[500] : "inherit",

                                }}

                                error={!checkisIP(ip)  && ip.length >= 1 ||  extractIPs(state.ips)?.includes(ip) || error.verify('groupip')}
                                helperText={!checkisIP(ip) && ip.length >= 1 ? "IP inválido" : extractIPs(state.ips)?.includes(ip) ? "IP/Network já adicionado" : ""}
                                label='IP ou IP/Mascara'
                                name={"ips"}
                                value={ip}
                                onChange={e => setIp(e.target.value)}
                            />
                        </FormControl>
                        <FormControl>


                            <Button
                                variant="contained"
                                color='primary'
                                disabled={!checkisIP(ip) || extractIPs(state.ips)?.includes(ip)}
                                sx={{ height: 56 }}

                                onClick={() => {
                                    const ips = extractIPs(state.ips) || []
                                    ips.push(ip)
                                    setState({ ...state, ips: Array.from(new Set(ips)).sort().join(",") })
                                    setIp("")
                                }}>
                                <AddIcon />
                            </Button>
                        </FormControl>
                    </Box>
                    <Box>
                        {extractIPs(state?.ips)?.map((ip, index) => <Chip   sx={{ m: .5, fontSize: 15 }} key={index} label={ip}  
                        
                        size='mediun'
                        onDelete={()=>{ 
                            const ips = extractIPs(state.ips).filter((v,i)=>v!=ip).sort()
                            setState({...state, ips: Array.from(new Set(ips)).join(",")})
                        }}
                         />)}
                    </Box>

                </Box>

            </FormDialog>
        </Box>
    )
}


