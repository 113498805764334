import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import GroupSelect from '../../components/GroupSelect';
import IpPoolSelect from '../../components/IpPoolSelect';
export default function Access() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({    
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Tools - Gate Acessos"
    }, [])

    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => setRefresh(true)}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Acessos'
                endpoint='/access'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton 
                                
                                onClick={() => { localStorage.removeItem('access'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('access.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: 'username',
                        label: 'Usuário',
                    },
                    {
                        name: 'groupname',
                        label: 'Grupo',
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton 
                                                disabled={!auth.hasPerm('access.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnSubmit, btnClose}) {
    const notify = useNotify()
    const error = useFormError()
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const handler401 = () => {
        navigator.push('/login')
    }
    useEffect(() => {
        setState(modelState)
        error.setErrors([])
        if (id && open) {
            setIsLoaded(false)
            get_one('/access', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)
            }).catch(e =>  console.log(e))
              
        }
    }, [id, open])

    const modelState = {
        username: '',
        value: '',
        groupname: '',
        password: '********',
        groupip: []
    }
    const [state, setState] = useState(modelState)
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/access', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
        
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                     }
                     handlerClose()
                     notify.setNotify({ open: true, message: 'Acesso editado com sucesso.', severity: 'success' })
                     error.setErrors([])
                    })
        }
        else {
            create('/access/create', state)
            .then(res => {
    
                if (res.data.errors) {
                    console.log(res.data.errors)
                    error.setErrors(res.data.errors)
                    return
                 }
                 handlerClose()
                 notify.setNotify({ open: true, message: 'Acesso criado com sucesso.', severity: 'success' })
                 error.setErrors([])

            })
            .catch(e => {
                notify.setNotify({ open: true, message: e.message, severity: 'error' })})
        }
        handlerSubmit()

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Acesso</Typography>
                        <IconButton
                        disabled={!auth.hasPerm('access.delete')}
                        sx={{ display: id ? 'block' : 'none' }}
                        onClick={()=>{
                             if (confirm('Deseja excluir o acesso?')){
                                    delete_one('/access', id).then(res => {
                                     
                                        if (res.data.errors) {
                                            notify.setNotify({ open: true, message: res.data.errors, severity: 'error' })
                                            return
                                        }
                                        notify.setNotify({ open: true, message: 'Acesso deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    }).catch(e => {
                                        if (e.response.status === 401) {
                                            handler401()
                                        }
                                        
                                    })
                             }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }} gap={2} pt={1}>

                    <TextField
                      
                        error={error.verify('username')}
                        helperText={error.msgError('username')}
                        label='Usuário'
                        name={'username'}
                        value={state.username || ''}
                        onChange={handleChange}
                    />
                    <TextField
                      
                        error={error.verify('password')}
                        helperText={error.msgError('password')}
                        type={showPassword ? "text" : "password"}
                        label='Senha'
                        name={'password'}
                        value={state.password}
                        onChange={e=> {
                
                            setState({...state, password: e.target.value})
                          }}
                          onFocus={e=>{
                            if (state.password == '********') {
                              setState({...state, password: ''})
                            }
                          }}
                          onBlur={e=>{{
                            if (state.password == '') {
                              setState({...state, password: '********'})
                            }
                          }}
                          }
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            )
                          }}
             
                    />
                    <GroupSelect
                        value={state.groupname}
                        handler={(e) => setState({ ...state, groupname: e.target.value })}
                        label={'Cargo'}
                    />
                    <IpPoolSelect
                        value={state?.groupip}
                        handler={(groupip) => setState({ ...state, groupip })}
                        label={'Pool de IP'}
                    
                    />
                </Box>

            </FormDialog>
        </Box>
    )
}


