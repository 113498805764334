import { Box, Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import ItemDashboard from "../../components/ItemDashboard";

export default function Dashboard() {
    const theme = createTheme({
        palette: {
          primary: {
            main: grey[900],
          },
          secondary: {
            main: grey[900],
          },
        },
      });

      React.useEffect(() => {
        document.title = "IPV7 Tools - Dashboard"
      }, [])
    return (
        <ThemeProvider theme={theme}>
        <Box display="flex">
            <ItemDashboard endpoint="/dashboard/driade/nas" label="Nas" />
            <ItemDashboard endpoint="/dashboard/driade/access" label="Acessos Gate" />
            <ItemDashboard endpoint="/dashboard/driade/groups" label="Grupos" />
            <ItemDashboard endpoint="/dashboard/driade/sessions" label="Sessões ativas" />
        </Box>
        <Box display="flex">
            <ItemDashboard endpoint="/dashboard/oceanide/servers" label="Servidores" />
            <ItemDashboard endpoint="/dashboard/oceanide/acls" label="ACL" />
            <ItemDashboard endpoint="/dashboard/oceanide/local_zones" label="Zonas Locais" />
            <ItemDashboard endpoint="/dashboard/oceanide/blacklist" label="Dominios Bloqueados" />
        </Box>
        </ThemeProvider>
    );
    }

