import * as React from 'react';
import ItemMenu, { ListItemMenu } from '../../components/ItemMenu';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import TvIcon from '@mui/icons-material/Tv';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SettingsIcon from '@mui/icons-material/Settings';
import DnsIcon from '@mui/icons-material/Dns';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SearchIcon from '@mui/icons-material/Search';
import KeyIcon from '@mui/icons-material/Key';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';


export const MainListItems = ({open})=>{
  

  
  return(
  <React.Fragment>

    <ItemMenu icon={<DashboardIcon />}
      name="Dashboard" path="/dashboard" perm={false}/>
    <ListItemMenu icon={<KeyIcon />} name={"Gate"} perms={["log_session",
                                                            "aceess",
                                                            "group",
                                                            "nas",
                                                            "log_fail",
                                                        ]}>
      <ItemMenu icon={<PersonIcon />}
        name="Acessos" path="/access" perm="access.get_all"/>
      <ItemMenu icon={<SummarizeIcon />}
        name="Pools de IP" path="/groupip" perm="access.get_all"/>
      <ItemMenu icon={<GroupIcon />}
        name="Grupos" path="/group"  perm="group.get_all" />
      <ItemMenu icon={<TvIcon />}
        name="NAS" path="/nas"  perm="nas.get_all"/>
      <ItemMenu icon={<EqualizerIcon />}
        name="Logs Fail" path="/log_auth_fail"  perm="log_fail.get_all"/>
      <ItemMenu icon={<EqualizerIcon />}
        name="Logs de Sessões" path="/log_session" perm="log_session.get_all" />
    </ListItemMenu>
    <ListItemMenu icon={<DnsIcon />} name={"DNS"} perms={["dns_node",
                                                          "dns_acl",
                                                          "dns_blacklist",
                                                          "dns_localzone",
                                                        ]}>
      <ItemMenu icon={<FormatAlignJustifyIcon />}
        name="Classes Permitidas" path="/dns_acl" perm="dns_acl.get_all" />
      <ItemMenu icon={<FormatAlignJustifyIcon />}
        name="Domínios bloqueados" path="/dns_blacklist" perm="dns_blacklist.get_all" />
      <ItemMenu icon={<DnsIcon />}
        name="Servidores DNS" path="/dns_node" perm="dns_node.get_all" />
      <ItemMenu icon={<FormatAlignJustifyIcon />}
        name="Zonas Locais" path="/dns_localzone" perm="dns_localzone.get_all" />
    </ListItemMenu>
    <ListItemMenu icon={<LibraryBooksIcon />} name={"Syslog"} perms={["pegeia"]}>
      <ItemMenu icon={<SearchIcon />} name="Busca" path="/pegeia_search" perm="pegeia.get_all" />
      <ItemMenu icon={<DnsIcon />} name="Datasources" path="/pegeia_source" perm="pegeia.get_all" />
      </ListItemMenu>
    <ListItemMenu icon={<SettingsIcon />} name={"Config"} perms={[
                                                                  "user",
                                                                  "role",
                                                                ]}>
      <ItemMenu icon={<PersonIcon />}
          name="Usuários" path="/user" perm="user.get_all" />
      <ItemMenu icon={<GroupIcon />}
          name="Cargos" path="/role" perm="role.get_all" />
      </ListItemMenu>

  </React.Fragment>
);
}
export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
