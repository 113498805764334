import React, { useEffect, useState } from 'react';
import FormDialog, { Text } from '../../components/Datatable/FormDialog';
import { Box, Button, IconButton, MenuItem, Snackbar, TextField, ThemeProvider, Tooltip, Typography, createTheme, setRef } from '@mui/material';
import { create, get_one, update, delete_one, get_all } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthProvider';
import { useLoading } from '../../components/LoadingProvider';
import {Datatable} from '../../components/Datatable';
import DateTimeToolbar from '../../components/DateTimeToolbar';
import api from '../../services/api';
import moment from "moment";


export default function Search() {
    const thenme = createTheme({
        palette: {
            mode: 'light',
            primary: {
              main: "#e01b29",
            },
        }
    })
    const notify = useNotify()
    const auth = useAuth()
    const loading = useLoading()
    const [data, setData] = useState([])
    const { isLoading, setLoading } = loading
    const [search, setSearch] = useState({})
    const submitSearch = (search) => {
        setSearch(search)
        setLoading(true)
        setData([])
        const {datasource, query, start, end} = search
        api.post(`/pegeia/source/${datasource}`, {query, start, end}).then((res) => {
            setData(res.data)
        }).catch((err) => {
            notify.setNotify({ open: true, message: "Um erro aconteceu, verifique se as informações de acesso do banco estão corretas.", severity: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }
    const fetchData = async (page = 0, rowsPerPage = false, order_by=undefined, order=undefined, query="") => {
        if (!search.datasource){
            return
        }
        setLoading(true)
        api.post(`/pegeia/source/${search.datasource}`, {
            query: search.query,
            start: search.start,
            end: search.end,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            order_by: order_by,
            order: order
            
        }).then((res) => {
            setData(res.data)
        }).catch((err) => {
            notify.setNotify({ open: true, message: "Um erro aconteceu, entre em contato com o administrador do sistema.", severity: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }

    React.useEffect(() => {
        document.title = "IPV7 Tools - Syslog Busca"
    }, [])
    return (
       
            
        <Datatable
        title={
            <SearchBar
            submitSearch={submitSearch}
                />
        }
        data = {data.data}
        columns = {[
            {
                name: 'DeviceReportedTime',
                label: 'Data',
                options:{
                    customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,

                } 
               
            },
            {
                name: 'FromHost',
                label: 'Equipamento',
                
               
            },
            {
                name: 'Message',
                label: 'Informação',
                
                
            },
            
        ]}
        options={{
            disableColumnSelector: true,
            selectableRows: 'none',
            viewColumns: false,
            search: false,
            print: false,
            download: false,
            filter: false,
            serverSide: true,
            rowsPerPageOptions: [10,30, 50, 100, 200],
            count: data?.total,
            onTableChange: (action, tableState) => {
                const {page,rowsPerPage, searchText, sortOrder
                    
                } = tableState
                  switch (action) {
                      case 'changePage':
                        fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)
                      break;
                      case 'changeRowsPerPage':
                        fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)
                      break;
                      case 'sort':
                        fetchData(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchText)
                      break;
                      default:
                        //console.log(action, tableState);
                    }
                    },

            
        }}
        />
          
    )
}


function SearchBar({ submitSearch }) {
    const [search, setSearch] = useState({
        datasource: '',
        query: '',
        start: '',
        end: ''
    })
    const theme = createTheme({
        palette: {
            mode: 'light',
            primary: {
              main: "#e01b29",
            },
        }
    })
    
    return (
        <ThemeProvider theme={theme}>
        <Box display='flex' flexDirection='row' component={"form"} alignItems='center'  >
          <SelectDatasource
                value={search.datasource}
                onChange={(e) => setSearch({ ...search, datasource: e.target.value })}
          />

            <TextField
                
                label="Busca"
                variant="outlined"
                size="small"
                name='query'
                value={search?.query}
                onChange={((e) =>   setSearch({ ...search, query: e.target.value }))}
                fullWidth
                sx={{ mr: 1 }}
                />
            <DateTimeToolbar handler={(value)=>  setSearch({ ...search, start: value})} label="Início"/>
            <DateTimeToolbar handler={(value)=> setSearch({ ...search, end: value})} label="Fim"/>
            <Button variant="contained" onClick={() => submitSearch(search)} sx={{ m:1}}
            disabled={search.datasource === '' || !search.start || !search.end}
            >Buscar</Button>
            
        </Box>
        </ThemeProvider>

    )
}

function SelectDatasource({ value, onChange }) {

   
    
    const [datasources, setDatasources] = useState([])
    useEffect(() => {
        get_all('/pegeia').then((response) => {
            setDatasources(response.data.data)
        })
    }, [])
    return (
 
      
        <TextField
            select
            label="Fonte de Dados"
            value={value}
            variant="outlined"
            size="small"
            onChange={(e) => onChange(e)}
            fullWidth
            sx={{ mr: 1 }}
        >
            {datasources.map((option) => (
                <MenuItem  value={option.id}>
                    {option.description}
                </MenuItem>
               
            ))}
        </TextField>
   
    )
}