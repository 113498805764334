import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import logo from "../../components/imgs/logo.png";
import syslog from "../../components/imgs/syslog.png"
import dns from "../../components/imgs/dns.png"
import gate from "../../components/imgs/gate.png"
import { useLocation } from "react-router-dom";

export default function Logo(){
    const location = useLocation()
    const { pathname } = location
   
    
    const pages_syslog = ["/pegeia_source", "/pegeia_search"]
    const pages_radius = ["/access","/group", "/nas", "/log_auth_fail",
        "log_session",
    ]
    const pages_dns = ["/dns_node", "/dns_localzone", "/dns_acl", "/dns_blacklist"]



    return (
        <img id="logo" 
        src={pages_dns.some(i=> i ===pathname) ? dns : pages_radius.some(i=> i===pathname) ? gate : pages_syslog.some(i=>i===pathname)? syslog: logo}
        
              style={{
                width: 100,
               
                
                
                
              }}
              />
    )
}