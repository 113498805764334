import React,{useEffect,useState} from 'react';
import api from '../services/api';
import { Paper, Typography } from '@mui/material';

export default function ItemDashboard({endpoint, label}) {
    const [state, setState] = useState({
        name:"...",
        value:0
    })
    const [isRender,setIsRender] = useState(false)
    useEffect(() => {
        api.get(endpoint).then(response => {
            setState({
                name: label,
                value: response.data.value
            })
            setIsRender(true)
        }).catch(error=>console.log(error))},[])
    return (
        isRender && <Item name={state.name} value={state.value} />
    )
}

function Item({name, value}) {
    return (
        <Paper elevation={2}
        
            sx={{
                m:2,
                p:2,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
               
            }}
        >
            <Typography
            sx={{
                textTransform: 'uppercase',
                color: 'primary.main',
                textAlign: 'center',
                fontFamily: 'Montserrat'
            }}
            >{name}</Typography>
            <Typography
            sx={{
                fontSize: '4em',
                fontFamily: 'Montserrat',
                fontWeight: 400,
                color: 'primary.main',
                textAlign: 'center',
                mb: 3
            
            }}
            >{value}</Typography>
        </Paper>
    );
}